:root {
  --main-purple: #51407f;
  --btn-purple: #605383;
  --btn-purple-light: #775ac7;
  --main-white: #F5F5F5;
  --home-white: #F8F8F8;
  --purple-gradient: #412566;
  --blue-gradient: #183580;
  --title-on-white: #6A6A6A;
  --subtitle-on-white: #414859;
  --topbar-text: #3E3E3E;
  --main-title-section: #222222;
  --card-title: #525960;
  --title-modal: #262626;
  --input-text-modal: #5F5F5F;
  --input-border-modal: #9A9A9A;
  --title-section-on-white: #62538a;
  --title-section-on-white-rgb: 98, 83, 138;
  --title-section-on-purple: #F7F7F7;
  --title-section-on-purple-rgb: 247, 247, 247;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: rgb(209, 209, 209);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--btn-purple);
  border-radius: 12px;
}

.btn-link {
  color: var(--btn-purple);
}

.form-check-input:checked {
  background-color: var(--btn-purple);
  border-color: var(--btn-purple);
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(172 142 255 / 25%);
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.h-100-vh {
  height: 100vh;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
}

.mt-2-4-em {
  margin-top: 2.4em;
}
